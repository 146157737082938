.card {
    text-align:center;
    display:table;
    position: relative;
    background-color:rgba(0,0,0,0.5);
    border-radius: 20px;
    max-width: 180px;
    padding: 5px 10px;
    top:-80px;
    left: -50px;
    padding-bottom: 15px;


}

.card h3 {

    font-size:24px;
    color:yellow;
    text-align:center;
    line-height: 0;
    white-space: nowrap;
    max-width: 250px;
    width: auto;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    font-weight: bold;
    padding: 5px 3cqb;

}

.card span {

    font-size:26px;
    color:white;
    line-height: 0px;
    text-align:center;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    font-weight: bold;

}

.card-city {
    text-align:center;
    display:table;
    position: relative;
    background-color:rgba(0,0,0,0.8);
    border-radius: 20px;
    max-width: 180px;
    padding: 5px 10px;
    top:-150px;
    left: -130px;


}

.card-city h3 {

    font-size:45px;
    color:yellow;
    text-align:center;
    line-height: 0;
    white-space: nowrap;
    width: auto;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    font-weight: bold;

}

.card-city span {

    font-size:24px;
    color:white;
    line-height: 0px;
    text-align:center;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    font-weight: bold;

}

@keyframes cardAnimation {
    0% { opacity: 0; transform: translateY(300px); }
    100% { opacity: 1;transform: translateY(0px)}
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1}
  } 
  

.forecast-panel {
    display: table;
    height:280px;
    max-height: 280px;
    width: auto;
    position: relative;
    top: -350px;
    right: 0px;
    margin: auto;
    z-index: 9999;

}

.forecast-panel .forecard{
    display: inline-table;
    width: 200px;
    text-align: center;
    height: 100%;
    margin-right: 10px;
    border-radius: 30px;
    background-color: rgba(22, 39, 59,0.9);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 5px solid rgba(255, 255, 255, 0.4);
    animation: cardAnimation 0.5s;
    transition-timing-function: ease-in;
}

.forecast-panel span.title{
    display: block;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    color: white;
    padding: 10px ;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    text-align: center;
}

.forecast-panel img{
    width: 100%;
    height: auto;
}

.forecast-panel .forecard .temp{
    display: block;
    position: absolute;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 65px;
    color: yellow;
    text-align: center;
    top: inherit;
    left: inherit;
    width: inherit;
    margin-top: -120px;
    text-shadow: 2px 2px rgba(0,0,0,0.8);

}

.forecast-panel .forecard .wind-hum-container {
    
    display: block;
    position: relative;
    top: 0px;
    left: 10px;
    padding: 10px;

   


}
.forecast-panel .forecard .wind-hum{
    display: block;
    position: relative;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: white;
    text-align: left;
    top: inherit;
    line-height: 40px;
    left: inherit;
    text-shadow: 2px 2px rgba(0,0,0,0.8);

}


.img-city-week {

    position: fixed;
    z-index: -1;
    width: 101vw;
    height: auto;
    top:-50vh;
    left: -50vw;
    animation: fadeIn 0.8s;
    transition-timing-function: ease-in;
    
}