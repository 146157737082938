html, body {
	margin: 0;
	padding: 0;
	height: 100%;
}

#map {
	width: 100%;
	height: 100%;
}

.bold {
	font-weight: bold;
}

.owm-layercontrol-header {
	font-size: larger;
	font-weight: bold;
	display: block;
	position: relative;
}

